const BASE_URL = "https://app.instantinvoice.com/";
//const BASE_URL = "http://ahsan.local:8000/";
//const BASE_URL = "http://staging.instantinvoice.com/";
// const BASE_URL = "http://localhost:3000/"

//For UAT Testing
// const BOLT_URL = "https://bolt-uat.cardpointe.com/api";
// const GATEWAY_API_URL = "https://boltgw-uat.cardconnect.com";
// const USER_NAME = "testing";
// const PASSWORD = "testing123";

// For PRODUCTION 
const BOLT_URL = "https://bolt.cardpointe.com/api";
//const GATEWAY_API_URL = "https://boltgw.cardconnect.com";
const GATEWAY_API_URL = "https://fts.cardconnect.com";
const USER_NAME = "intinvprodtst";
const PASSWORD = "u7K@mYFo#uCxLhz!ayqAN";

export const DEV_TOKEN = "Token ";
export const STAGING_TOKEN = "Token ";


const StorageEnum = {
    CP_KEY : "CP-KEY",
    UUID : "UUID",
    SESSION_ID : "SID",
    APPID: "APPID",
    FULLNAME: "FULLNAME",
    PPBUNDLE: "PP_BUNDLE",
    SOFTWARE_TYPE: "SOFTWARE_TYPE",
    QBO_CUSTOMERS: "QBO_CUSTOMERS",
    CUSTOMER_ID: "CUSTOMER_ID",
    CUSTOMER_NAME: "CUSTOMER_NAME",
    PERMISSIONS: "PERMISSIONS",
    IS_FROM_CP:"IS_FROM_CP"
};

const SoftwareTypeEnum = {
    QuickBooksOnline : 1,
    QuickBooksDesktop : 2,
    QuickBooksPOS : 3,
    NMIInvoicing : 4,
    InstantInvoiceStandalone: 5
};

const ApplicationIDEnum ={
    MerchantBoarding : 1,
    QuickBooksOnline : 2,
    QuickBooksDesktop : 3,
    NMIInvoicing : 4,
    QuickBooksPOS : 5,
    CustomerPortal : 6
}
export {
    BASE_URL,
    BOLT_URL,
    USER_NAME,
    PASSWORD,
    GATEWAY_API_URL,
    StorageEnum,
    SoftwareTypeEnum,
    ApplicationIDEnum,
}